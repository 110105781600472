import Layout from "@components/Layout";
import ROUTES from "@config/routes";
import { useHomeUserContext } from "@context/HomeUserContext";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { DiscountProvider, useDiscountContext } from "./DiscountContext";
import TableDiscount from "./TableDiscount";

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
      color: theme.palette.primary.highlightedplus,
    },
    titleCard: {
      color: theme.palette.text.primary,
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
      marginLeft: "2rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
      },
      borderRadius: "8px",
    },
    inputLabel: {
      position: "absolute",
      top: "-4px",
      left: "12px",
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: theme.palette.text.hint,
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    itemSelectTitle: {
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      marginTop: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    itemSelectSubTitle: {
      color: theme.palette.grey[100],
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.6rem",
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    formControl: {
      borderRadius: "8px",
      width: "px",
      height: "50px",
    },
  })
);

const PageContainer: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { loading } = useDiscountContext();
  const { documentSelected } = useHomeUserContext();

  const commonStyle: any = {
    fontFamily: "Montserrat",
    borderRadius: "2px",
    fontWeight: "bold",
    textTransform: "none",
    background: theme.palette.primary.main,
    color: theme.palette.text.hint,
    padding: isMobile ? "10px 30px" : "10px 30px",
    boxShadow: "none",
    fontSize: isMobile ? "12px" : "",
    height: "56px",
  };

  return (
    <Layout>
      <Grid
        item
        xs={12}
        style={{
          margin: isMobile ? 0 : "0 8rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Typography
          variant={isMobile ? "subtitle1" : "h5"}
          align="left"
          className={classes.formTitle}
        >
          Descontos
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "end",
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Button
          type="submit"
          style={commonStyle}
          onClick={() => history.push(ROUTES.USER_ROUTES.PRODUCT)}
        >
          Ir para produtos
        </Button>
      </Grid>

      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <TableDiscount
          isLoading={loading}
          companyName={documentSelected.label}
        />
      </div>
    </Layout>
  );
};

const Discount: React.FC = () => {
  return (
    <DiscountProvider>
      <PageContainer />
    </DiscountProvider>
  );
};

Discount.displayName = "Discount";

export default Discount;
