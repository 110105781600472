import DatePickerGeneric from "@components/DatePickerGeneric/DatePickerGeneric";
import {
  AlertDeliveryDateIcon,
  CalendarChangeDeliveryDateDisabledIcon,
  CalendarChangeDeliveryDateIcon,
  CheckGreenIcon,
  DumpIcon,
  FoldedPaperDisabledIcon,
  FoldedPaperIcon,
} from "@components/Icons";
import { ModalGeneral } from "@components/ModalGeneral/ModalGeneral";
import StatusTag from "@components/StatusTagGeneric/StatusTagGeneric";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IQueryOrderDataDTO } from "@modules/orders/dtos/IQueryOrderDataDTO";
import { IQueryOrdersDTO } from "@modules/orders/dtos/IQueryOrdersDTO";
import {
  DownloadNfeQuery,
  DownloadType,
} from "@modules/orders/models/DownloadNfeQuery";
import { ICreatePDFOrdersService } from "@modules/orders/models/ICreatePDFOrdersService";
import { IDownloadByLineService } from "@modules/orders/models/IDownlodByLine";
import { IUpdateOrderService } from "@modules/orders/models/IUpdateOrderService";
import { ITEMS_PER_PAGE } from "@pages/User/MyOrders";
import DetailsOrdersStatus, {
  statusType,
} from "@pages/User/MyOrders/DetailsOrdersStatus";
import FormQueryValidationSchema from "@pages/User/MyOrders/FormQueryValidationSchema";
import AppError from "@utils/AppError";
import { DownloadPDF, downloadThisWindow } from "@utils/MyOrders/download";
import { formatCurrency, formatCurrency4DecimalsPlace } from "@utils/index";
import { getValuesFilters } from "@utils/localStorageUtils";
import { format } from "date-fns";
import { useFlags } from "flagsmith/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useFormQuery } from "./FormQueryContext";
import { ModalCancelOrder } from "./ModalCancelOrder";
import ModalTableOrders from "./Sections/ModalTableOrders";
import { IQuery } from "./interface";
import { financialStatusAcronym } from "./models/ENUM_MY_ORDERS";
import { ITableOrder, ITableOrderTitles } from "./models/ITableOrderTitles";
import {
  getFinancialStatus,
  getFinancialStatusPTBR,
  isBlockCalendarDelivery,
} from "./utilsOrders/utilsMyOrders";

interface ITableOrdersProps {
  page: number;
}

enum TypePaymentEnum {
  B = "À Prazo",
  T = "À vista",
}

const TableOrders: React.FC<ITableOrdersProps> = (props) => {
  const { snackbar } = useDialogAlert();
  const valuesFiltersStorage = getValuesFilters("valuesFilters");
  const filtersStorageObject = valuesFiltersStorage
    ? JSON.parse(valuesFiltersStorage ? valuesFiltersStorage : "")
    : "";
  const userState = useUserState();
  const classes = useStyles();
  const formQueryContext = useFormQuery();
  const iocContext = useIoCContext();
  const createPDFOrdersService = iocContext.serviceContainer.get<
    ICreatePDFOrdersService
  >(Types.Orders.ICreatePDFOrdersService);
  const { values } = useFormikContext<IQuery>();
  const [orderToCancel, setOrderToCancel] = useState<IQueryOrderDataDTO | null>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const [openRows, setOpenRows] = useState<boolean[]>([]);
  const [loadPDF, setLoadPDF] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rowLoading, setRowLoading] = useState<boolean[]>(
    Array(formQueryContext.dataQuery.length).fill(false)
  );

  const downloadByService = iocContext.serviceContainer.get<
    IDownloadByLineService
  >(Types.Orders.IDownloadByLineService);

  const updateOrder = iocContext.serviceContainer.get<IUpdateOrderService>(
    Types.Orders.IUpdateOrderService
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [modalDeliveryCalendar, setModalDeliveryCalendar] = useState<boolean>(
    false
  );
  const [modalSucessDelivery, setModalSucessDelivery] = useState<boolean>(
    false
  );
  const [modalErrorDelivery, setModalErrorDelivery] = useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

  const [numberOrderByLine, setNumberOrderByLine] = useState("");
  const [, setFreightTypeByLine] = useState("");

  const [indexClicked, setIndexClicked] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState(false);

  const today = new Date();
  let minDateAllowed = today;

  const [selectedDate, setSelectedDate] = useState(deliveryDate || today);

  const featureFlagDeliveryDate = useFlags(["your_orders_update_delivery_date"])
    .your_orders_update_delivery_date.enabled;

  const handleOpenDeliveryCalendar = () => {
    setModalDeliveryCalendar(true);
  };

  const handleCloseDeliveryCalendar = () => {
    setModalDeliveryCalendar(false);
  };

  const handleCloseModalSuccessDelivery = () => {
    setModalSucessDelivery(false);
  };

  const handleCloseModalErrorDelivery = () => {
    setModalErrorDelivery(false);
  };
  const handleUpdateDeliveryDate = async () => {
    try {
      setIsLoading(true);
      const formattedDate = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : "";

      await updateOrder.execute({
        orderId: numberOrderByLine,
        requestedDeliveryDate: formattedDate,
      });

      setModalSucessDelivery(true);
    } catch (error) {
      setModalErrorDelivery(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getColumnsOrder = (): ITableOrder[] => {
    const notVisibleColumnCancelOrder = formQueryContext.dataQuery.map(
      (item) => item.statusAtem === financialStatusAcronym.FT_FATURADO
    )[0];

    let titleColumnTableOrders: ITableOrder[] = ITableOrderTitles;

    // Ocultar o título de cancelar pedido se o status for faturado

    if (notVisibleColumnCancelOrder) {
      titleColumnTableOrders = titleColumnTableOrders.filter(
        (column) => column.id !== "cancelOrder"
      );
    }

    // Ocultar o título de alterar data entrega se a feature flag estiver desativada
    if (!featureFlagDeliveryDate) {
      titleColumnTableOrders = titleColumnTableOrders.filter(
        (column) => column.id !== "calendarUpdateDelivery"
      );
    }

    return titleColumnTableOrders;
  };

  const columnsOrderMocked: ITableOrder[] = getColumnsOrder();

  const toggleRow = (index: number) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const fetchDownload = async (query: DownloadNfeQuery) => {
    try {
      const response = await downloadByService.execute(query);
      switch (query.type) {
        case "pdf":
          downloadThisWindow(response, "pdf", query);
          break;
      }
    } catch (error) {
      snackbar({
        message: "Erro ao tentar baixar Nota Fiscal !",
        variant: "error",
      });
    }
  };

  const downloadByLineTable = async (
    type: DownloadType,
    nfKey?: string,
    rowIndex?: number
  ) => {
    if (!nfKey || rowIndex === undefined) return;

    try {
      setRowLoading((prevLoading) => {
        const newLoading = [...prevLoading];
        if (rowIndex >= 0) newLoading[rowIndex] = true;
        return newLoading;
      });

      switch (type) {
        case "pdf":
          await fetchDownload({
            type,
            codes: [nfKey],
          });
          break;
        default:
          break;
      }
    } catch (error) {
      snackbar({
        message: "Erro ao tentar baixar Nota Fiscal !",
        variant: "error",
      });
    } finally {
      setRowLoading((prevLoading) => {
        const newLoading = [...prevLoading];
        if (rowIndex >= 0) newLoading[rowIndex] = false;
        return newLoading;
      });
    }
  };

  const handleDownloadExcel = async () => {
    try {
      setLoadPDF(true);
      if (values.startDate && values.endDate) {
        const casted = FormQueryValidationSchema.cast(values);
        if (userState.listCNPJ[0]) {
          let CNPJ = [`${userState.listCNPJ[0].CNPJ}`];
          if ((casted?.CNPJ as string[]).length !== 0) {
            CNPJ = casted?.CNPJ as string[];
          }
          const queryOrdersPDF: IQueryOrdersDTO = {
            companies: filtersStorageObject.CNPJ
              ? filtersStorageObject.CNPJ
              : CNPJ,
            beginDate: filtersStorageObject.dateRange[0]
              ? filtersStorageObject.dateRange[0]
              : casted?.dateRange[0],
            endDate: filtersStorageObject.dateRange[1]
              ? filtersStorageObject.dateRange[1]
              : casted?.dateRange[1],
            filterBy: "deliveryDate",
            statusPortalCliente: filtersStorageObject.statusSale
              ? filtersStorageObject.statusSale
              : casted?.statusSale,
            page: props.page > 1 ? props.page : 1,
            limit: ITEMS_PER_PAGE,
            type: "v2",
          };

          const urlFile = await createPDFOrdersService.searchExcel(
            queryOrdersPDF
          );
          DownloadPDF(urlFile, "relatorio-dos-pedidos");
        } else {
          throw new Error("Nao possui CNPJ para realizar a consulta");
        }
      }
    } catch (error) {
      if (error instanceof AppError) {
        snackbar({
          message: `Ocorreu um erro ao buscar relatorio ! ${error.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoadPDF(false);
    }
  };

  const isButtonDisabled = () => {
    return (
      !selectedDate ||
      (deliveryDate &&
        selectedDate.toISOString() === deliveryDate.toISOString()) ||
      undefined
    );
  };

  const calculateHeight = () => {
    if (isMobile) {
      return "auto";
    }

    const contentLength = formQueryContext.dataQuery?.length || 0;
    return contentLength < 5 ? "auto" : "73vh";
  };

  function getPaymentType(paycond: number): string {
    if (paycond === 0) {
      return TypePaymentEnum.T;
    }

    const quantityDay = paycond === 1 ? "Dia" : "Dias";

    return `${paycond.toString()} ${quantityDay}` || "";
  }

  return (
    <Box className={classes.paperTable}>
      {loadPDF ? (
        <CircularProgress
          color="primary"
          style={{
            marginLeft: "10px",
            height: "2.0rem",
            width: "2.0rem",
          }}
        />
      ) : (
        <Button
          className={classes.buttonDownloadReport}
          color="primary"
          onClick={() => handleDownloadExcel()}
        >
          Baixar relatório completo
        </Button>
      )}

      <Grid container justify="center"></Grid>
      <TableContainer
        className={classes.TableContainer}
        style={{ height: calculateHeight() }}
      >
        <Table
          className={classes.Table}
          stickyHeader
          style={{ borderSpacing: isMobile ? "0px 13px" : "0px 20px" }}
        >
          <TableHead>
            <TableRow className={classes.TableRow}>
              {columnsOrderMocked.map((item, index) => (
                <>
                  {item.isVisibleMobile && isMobile && (
                    <TableCell
                      key={index}
                      align={item.id === "deliveryDate" ? "left" : item.align}
                      className={classes.TableCellHead}
                      style={{
                        padding:
                          isMobile && item.id === "deliveryDate"
                            ? "16px 0px"
                            : "",
                        fontSize: "1.3rem",
                      }}
                      colSpan={item.id === "deliveryDate" ? 3 : 1}
                    >
                      {item.option}
                    </TableCell>
                  )}

                  {!isMobile && (
                    <TableCell
                      key={index}
                      align={item.align}
                      className={classes.TableCellHead}
                    >
                      {item.option}
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {formQueryContext.dataQuery.map((ele, index) => {
              return (
                <Fade
                  key={ele.numberOrder}
                  in={Boolean(formQueryContext.dataQuery.length)}
                  timeout={1000}
                >
                  <>
                    <TableRow>
                      {!isMobile && (
                        <TableCell
                          align="center"
                          className={classes.TableCellFromData}
                        >
                          <Tooltip title="Baixar NF">
                            <IconButton
                              color="primary"
                              disabled={
                                ele.statusAtem !==
                                financialStatusAcronym.FT_FATURADO
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                downloadByLineTable("pdf", ele?.nfKey, index);
                              }}
                            >
                              {rowLoading[index] ? (
                                <CircularProgress
                                  color="primary"
                                  style={{ height: "2.5rem", width: "2.5rem" }}
                                />
                              ) : (
                                <>
                                  {ele.statusAtem !==
                                  financialStatusAcronym.FT_FATURADO ? (
                                    <FoldedPaperDisabledIcon />
                                  ) : (
                                    <FoldedPaperIcon />
                                  )}
                                </>
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}

                      <TableCell
                        align={isMobile ? "left" : "center"}
                        style={{
                          fontSize: isMobile ? "1.3rem" : "",
                          padding: isMobile ? "15px 21px" : "",
                        }}
                        className={classes.TableCellFromData}
                      >
                        {ele.numberOrder}
                      </TableCell>
                      <TableCell
                        align={isMobile ? "left" : "right"}
                        className={classes.TableCellFromData}
                        style={{
                          padding: isMobile ? "0px 0px" : "",
                          fontSize: isMobile ? "1.3rem" : "",
                        }}
                      >
                        {((ele.deliveryDate as unknown) as string) !==
                          "0000-00-00" &&
                          format(new Date(ele.deliveryDate), "dd/MM/yyyy")}
                      </TableCell>

                      {isMobile && (
                        <>
                          <TableCell
                            align="center"
                            style={{
                              border: "none",
                              padding: "16px 0px",
                            }}
                            className={classes.TableCellFromData}
                          >
                            <IconButton
                              size={"small"}
                              onClick={() => {
                                setIndexClicked(index);
                                setOpenDialog(true);
                              }}
                            >
                              <ArrowForwardIosIcon
                                color="primary"
                                fontSize="small"
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.TableCellFromData}
                          >
                            <Tooltip title="Excluir Pedido">
                              <IconButton
                                size={"small"}
                                color="primary"
                                onClick={() => {
                                  setOrderToCancel(ele);
                                  setOpenModal(true);
                                }}
                              >
                                <DumpIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </>
                      )}

                      {!isMobile && (
                        <>
                          <TableCell
                            align="center"
                            className={classes.TableCellFromData}
                          >
                            {ele.filialID}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.TableCellFromData}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {ele.companyName && ele.companyName}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.TableCellFromData}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {`${ele.productID} - ${ele.productName}`}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.TableCellFromData}
                          >
                            {ele.quantity.toLocaleString("pt-BR")}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.TableCellFromData}
                          >
                            {formatCurrency4DecimalsPlace(ele.price)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.TableCellFromData}
                          >
                            {formatCurrency(ele.totalPrice)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.TableCellFromData}
                          >
                            {getPaymentType(ele.payCond)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.TableCellFromData}
                          >
                            {ele.freightType}
                          </TableCell>

                          <TableCell
                            align="right"
                            className={classes.TableCellFromData}
                          >
                            <StatusTag
                              text={getFinancialStatusPTBR(ele.statusAtem)}
                              type={getFinancialStatus(ele.statusAtem)}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.TableCellFromData}
                          >
                            <Paper
                              elevation={0}
                              style={{
                                color: "#D91F05",
                              }}
                            >
                              <Typography
                                align="center"
                                variant="button"
                                onClick={() => {
                                  toggleRow(index);
                                }}
                                style={{
                                  textTransform: "none",
                                  fontFamily: "Montserrat",
                                  cursor: "pointer",
                                }}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        color: "#D91F05",
                                        lineHeight: "0px",
                                        margin: "auto",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Ver mais
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {openRows[index] ? (
                                      <ExpandMoreIcon />
                                    ) : (
                                      <ExpandLessIcon />
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </Paper>
                          </TableCell>
                          {ele.allowedToCancel && (
                            <TableCell
                              align="center"
                              className={classes.TableCellFromData}
                            >
                              <Tooltip title="Excluir Pedido">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setOrderToCancel(ele);
                                    setOpenModal(true);
                                  }}
                                >
                                  <DumpIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}

                          {featureFlagDeliveryDate && (
                            <TableCell
                              align="center"
                              className={classes.TableCellFromData}
                              colSpan={2}
                            >
                              <Tooltip
                                title={
                                  isBlockCalendarDelivery(
                                    ele.freightType,
                                    ele.statusAtem
                                  )
                                    ? "Não é possível alterar data de agendamento para pedidos faturados"
                                    : "Alterar data de entrega"
                                }
                                placement="left"
                              >
                                <span>
                                  <IconButton
                                    color="primary"
                                    disabled={isBlockCalendarDelivery(
                                      ele.freightType,
                                      ele.statusAtem
                                    )}
                                    onClick={() => {
                                      if (
                                        !isBlockCalendarDelivery(
                                          ele.freightType,
                                          ele.statusAtem
                                        )
                                      ) {
                                        handleOpenDeliveryCalendar();
                                        setFreightTypeByLine(ele.freightType);
                                        setNumberOrderByLine(ele.numberOrder);
                                        setDeliveryDate(ele.deliveryDate);
                                      }
                                    }}
                                  >
                                    {isBlockCalendarDelivery(
                                      ele.freightType,
                                      ele.statusAtem
                                    ) ? (
                                      <CalendarChangeDeliveryDateDisabledIcon />
                                    ) : (
                                      <CalendarChangeDeliveryDateIcon />
                                    )}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>

                    {openRows[index] && (
                      <TableRow>
                        <TableCell
                          style={{ padding: 0, margin: "0px" }}
                          colSpan={14}
                        >
                          <DetailsOrdersStatus
                            tipoFrete={ele.freightType}
                            placa={ele.vehiclePlate}
                            status={ele.statusPortalCliente as statusType}
                            latitude={ele.latitude || 0}
                            longitude={ele.longitude || 0}
                            orderNumber={ele.numberOrder}
                            productName={ele.productName}
                            productID={ele.productID}
                            quantity={ele.quantity}
                            companyName={ele.companyName}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </Fade>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalTableOrders
        open={openDialog}
        dataOrder={(formQueryContext.dataQuery || [])[indexClicked]}
        handleOnClose={() => setOpenDialog(false)}
      />

      {orderToCancel ? (
        <ModalCancelOrder
          open={openModal}
          orderData={orderToCancel}
          closeModal={() => {
            setOrderToCancel(null);
            setOpenModal(false);
          }}
        />
      ) : (
        <></>
      )}

      {modalDeliveryCalendar && (
        <ModalGeneral
          open={modalDeliveryCalendar}
          closeModal={handleCloseDeliveryCalendar}
          titleHeaderModal={"Alterar data da entrega"}
          resetPadding={true}
          width={isMobile ? "100%" : "500px"}
          height={isMobile ? "420px" : "580px"}
          labelButtonCancel={"Cancelar"}
          labelButtonSubmit={"Alterar data"}
          onClickSubmit={handleUpdateDeliveryDate}
          hasDialogActions={true}
          isDisabledSubmitted={isButtonDisabled()}
          isLoading={isLoading}
        >
          <Grid xs={12}>
            <Typography
              className={classes.DescriptionChangeDeliveryCalendar}
              style={{
                fontSize: isMobile ? "1.2rem" : "1.6rem",
              }}
            >
              Selecione a nova data de entrega.
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DatePickerGeneric
              minDate={minDateAllowed}
              dateSelected={deliveryDate}
              onDateChange={(newDate) => setSelectedDate(newDate)}
              disableSundays={true}
            />
          </Grid>
        </ModalGeneral>
      )}

      {modalSucessDelivery && (
        <ModalGeneral
          open={modalSucessDelivery}
          closeModal={handleCloseModalSuccessDelivery}
          title={"Data de entrega alterada com sucesso."}
          icon={<CheckGreenIcon style={{ width: "80px", height: "80px" }} />}
          width={isMobile ? "100%" : "500px"}
          height={isMobile ? "320px" : "400px"}
          hasDialogActions={false}
          resetPadding={false}
        >
          <Typography className={classes.DescriptionSucessDelivery}>
            Você alterou a data de entrega do pedido{" "}
            <span className={classes.SpanSubTitleDelivery}>
              {numberOrderByLine}
            </span>{" "}
            para
            <span className={classes.SpanSubTitleDelivery}>
              {selectedDate
                ? format(selectedDate, "dd/MM/yyyy")
                : "data não definida"}
            </span>
          </Typography>
        </ModalGeneral>
      )}

      {modalErrorDelivery && (
        <ModalGeneral
          open={modalErrorDelivery}
          closeModal={handleCloseModalErrorDelivery}
          title={"Ocoreu um erro ao tentar alterar data de agendamento"}
          icon={
            <AlertDeliveryDateIcon style={{ width: "80px", height: "80px" }} />
          }
          width={isMobile ? "100%" : "500px"}
          height={isMobile ? "360px" : "400px"}
        >
          <Typography className={classes.DescriptionSucessDelivery}>
            Tente novamente!
          </Typography>
          {/* 
          {freightTypeByLine === freighTypesEnum.CIF ? (
            <Typography className={classes.DescriptionPermittedTimeDelivery}>
              Horário permitido: <br></br> 00h00 às 13h00
            </Typography>
          ) : (
            <Typography className={classes.DescriptionPermittedTimeDelivery}>
              Horário permitido: <br></br>
              00h00 às 14h00
            </Typography>
          )} */}
        </ModalGeneral>
      )}
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DescriptionChangeDeliveryCalendar: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      opacity: "0.3",
    },
    DescriptionPermittedTimeDelivery: {
      fontSize: "1.4rem",
      paddingTop: "10px",
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      fontWeight: "bold",
    },
    SpanSubTitleDelivery: {
      color: "#D91F05",
      fontWeight: "bold",
    },
    DescriptionSucessDelivery: {
      fontSize: "1.6rem",
      paddingTop: "10px",
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.3rem",
      },
    },
    TitleOrder: {
      color: "#3E3D3D",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      fontSize: "3rem",
      paddingBottom: "20px",
    },
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },
    Table: {
      borderCollapse: "separate",
    },
    TableRow: {
      background: "#fff",
    },
    TableCell: {
      padding: "6px 16px 6px 16px",
      marginBottom: "20px",
    },
    TableCellHead: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "DM Sans",
      background: "#fff",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
    paperTable: {
      background: "#F5F5F5",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["900"],
        borderRadius: ".4rem",
      },
    },
    iconImg: {
      filter:
        "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%);",
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    paperSituationSuccess: {
      color: "#0BB873",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    paperSituationError: {
      color: "#F97A91",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },

    TableCellFromData: {
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      fontFamily: "DM Sans",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
    buttonDownloadReport: {
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "2.875rem",
      height: "auto",
    },
  })
);

export default TableOrders;
