import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LogoutIcon } from "@components/Icons";
import homeIconCollapse from "../../assets/icon_collapse_sidebar.svg";
import iconMenu from "../../assets/hamburguer_menu.svg";
import logoAtem from "../../assets/logo-atem.svg";
import { goToLogout } from "@utils/index";
import Header from "@components/Header";
import CustomAvatar from "@components/CustomAvatar";
import { getUserNameLogin, checkSidebarAdmin } from "@utils/localStorageUtils";
import useStylesSidebar from "./model/useStylesSidebar";
import ListDrawerAdmin from "./sections/ListDrawerAdmin";
import ListDrawerUser from "./sections/ListDrawerUser";
import { routesToHide } from "./model/routesToHide";
import HeaderMobile from "@components/HeaderMobile";

const Sidebar: React.FC<{
  invisibleToolbar?: boolean;
  invisibleToolbarMobile?: boolean;
  isMobile?: boolean;
}> = ({ children, invisibleToolbar, invisibleToolbarMobile, isMobile }) => {
  const history = useHistory();
  const classes = useStylesSidebar();
  const nameUser = getUserNameLogin();
  const sidebarCurrent = checkSidebarAdmin();

  const [open, setOpen] = React.useState(false);
  const [isInvisible, setIsInvisible] = useState<Boolean>(
    invisibleToolbar ?? true
  );

  const [isSidebarAdmin, setSidebarAdmin] = useState<boolean>(false);

  useEffect(() => {
    setSidebarAdmin(sidebarCurrent);

    if (invisibleToolbar) {
      if (isInvisible === invisibleToolbar) return;
      if (isInvisible !== invisibleToolbar) {
        const updateStatusToolbar = () => {
          setIsInvisible(invisibleToolbar);
        };
        updateStatusToolbar();
      }
    }

    setIsInvisible(routesToHide.includes(history.location.pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, routesToHide, invisibleToolbar]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return isMobile ? (
    <SidebarMobile
      invisibleToolbarMobile={invisibleToolbarMobile}
      isSidebarAdmin={isSidebarAdmin}
    >
      {children}
    </SidebarMobile>
  ) : (
    <div
      className="Sidebar-index"
      style={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={`${clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })} Sidebar-appbar`}
        style={{ display: invisibleToolbar ? "none" : "block" }}
      >
        <Toolbar>{!isInvisible && <Header />}</Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={`${clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })} Sidebar-drawer`}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box className={classes.containerHeaderToolbarOpen}>
          {open && (
            <Box className={classes.containerAvatar}>
              <CustomAvatar name={nameUser} />
              <Box className={classes.containerAvatarText}>
                <Typography className={classes.userName}>{nameUser}</Typography>
              </Box>
            </Box>
          )}
          {open && (
            <Box
              className={classes.toolbar}
              style={{ textAlign: "right", width: "100%" }}
            >
              <IconButton onClick={handleDrawerClose}>
                <img
                  alt={"homeIconCollapse"}
                  src={homeIconCollapse}
                  width="14px"
                  height="10px"
                />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={classes.containerButtonMenu}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <img alt={"iconMenu"} src={iconMenu} width="20px" height="16px" />
          </IconButton>
        </Box>
        <Divider className={classes.dividerStyle} />
        {isSidebarAdmin ? <ListDrawerAdmin /> : <ListDrawerUser />}
        <ListItem
          button
          className={clsx(classes.listItem)}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
          component="a"
          onClick={() => goToLogout()}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "-7px",
          }}
        >
          <ListItemIcon>
            <div className={classes.borderMenuNormal}></div>
            <LogoutIcon style={{ width: "22.6px" }} className={classes.icon} />
          </ListItemIcon>
          <Typography className={classes.textMenuItem}>Sair</Typography>
        </ListItem>
        <Box className={classes.containerLogoMenuOpened}>
          <img alt={"logoAtem"} src={logoAtem} width={37} height={28} />
        </Box>
      </Drawer>
      <main
        style={{
          flexGrow: 1,
          overflowX: "hidden",
        }}
      >
        <div
          className={"sidebar-app"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0 8px",
            minHeight: "56px",
            // ...theme.mixins.toolbar,
          }}
        />
        {children}
      </main>
    </div>
  );
};

interface SidebarMobileProps {
  invisibleToolbarMobile?: boolean;
  isSidebarAdmin: boolean;
  children: ReactNode;
}

const SidebarMobile: React.FC<SidebarMobileProps> = ({
  children,
  isSidebarAdmin,
  invisibleToolbarMobile,
}) => {
  return (
    <Box className={"sidebar-mobile"}>
      <HeaderMobile
        invisibleToolbar={invisibleToolbarMobile}
        isSidebarAdmin={isSidebarAdmin}
      />
      {children}
    </Box>
  );
};

export default Sidebar;
