import React, { ReactNode } from "react";
import Sidebar from "@components/Sidebar";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  children: ReactNode;
  disableFooter?: boolean;
  disableToolbar?: boolean;
  disableToolbarMobile?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  disableToolbar,
  disableToolbarMobile = false,
}) => {
  const theme = useTheme();
  const isMobileCurrent = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <div
      className="layout-app"
      style={{
        fontFamily: theme.typography.fontFamily,
        maxWidth: "100%",
        overflow: "none",
      }}
    >
      <Sidebar
        invisibleToolbar={disableToolbar}
        invisibleToolbarMobile={disableToolbarMobile}
        isMobile={isMobileCurrent}
      >
        <div
          style={{
            overflow: "hidden",
            maxWidth: "100%",
            width: "100%",
          }}
        >
          {children}
        </div>
      </Sidebar>
    </div>
  );
};

export default Layout;
