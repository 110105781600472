import React from "react";
import { List } from "@material-ui/core";

import { useAuth } from "@context/auth/AuthContext";
import { useUserState } from "@context/UserContext";
import { useFlags } from "flagsmith/react";

import { SIDEBAR_USER_FLAGS } from "@utils/featureFlags";
import { generatePermissionMatcher } from "@utils/index";
import { TypesPermissions } from "@components/Routes/TypesPermissions";
import ROUTES from "@config/routes";

import {
  HomeIcon,
  HomeIconActive,
  PriceIcon,
  PriceIconActive,
  RoadLoadIcon,
  SimulationIcon,
  SimulationIconActive,
  ContactUsIcon,
  ContactUsIconActive,
  ChangePasswordIcon,
  ChangePasswordIconActive,
  EmployeesIcon,
  EmployeesIconActive,
  CampaignMetricsIconActive,
  CampaignMetricsIcon,
  GoalsIcon,
  GoalsIconActive,
  NozzleIcon,
  NozzleActiveIcon,
  ColetaIcon,
  ColetaIconActive,
} from "@components/Icons";

import useStylesSidebar from "../model/useStylesSidebar";
import { ISidebarItems } from "../model/ISidebarItems";
import { Roles } from "@shared/model";
import SidebarMenuItem from "./SidebarMenuItem";

const ListDrawerUser: React.FC = () => {
  const classes = useStylesSidebar();
  const userState = useUserState();

  const { permissionSet } = useAuth();

  const menuListUser: ISidebarItems[] = [
    {
      icon: <HomeIcon className={classes.icon} />,
      iconActive: <HomeIconActive className={classes.icon} />,
      text: "Início",
      link: ROUTES.USER_ROUTES.HOME,
      flag: "user_sidebar_home_user",
      hasPermission: generatePermissionMatcher(TypesPermissions.HOME),
    },
    {
      icon: <PriceIcon className={classes.icon} />,
      iconActive: <PriceIconActive className={classes.icon} />,
      text: "Preços de produtos ATEM",
      link: ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
      flag: "user_sidebar_price_product_atem",
      hasPermission: generatePermissionMatcher(TypesPermissions.PRODUCT_PRICE),
    },
    {
      icon: <RoadLoadIcon className={classes.icon} />,
      iconActive: <RoadLoadIcon className={classes.icon} />,
      text: "Agenda e Vem",
      link: ROUTES.USER_ROUTES.ROADLOAD,
      flag: "user_sidebar_road_load",
      hasPermission: generatePermissionMatcher(TypesPermissions.DRIVER),
      onClick: () => {
        sessionStorage.setItem("sheetRoadLoad", JSON.stringify(false));
      },
    },
    {
      icon: <SimulationIcon className={classes.icon} />,
      iconActive: <SimulationIconActive className={classes.icon} />,
      text: "Simular carregamento",
      link: ROUTES.USER_ROUTES.SIMULATE_ORDER,
      flag: "user_sidebar_hide_simulate_loading",
      hasPermission: generatePermissionMatcher(TypesPermissions.SIMULATION),
    },
    {
      icon: <ContactUsIcon className={classes.icon} />,
      iconActive: <ContactUsIconActive className={classes.icon} />,
      text: "Fale conosco",
      link: ROUTES.USER_ROUTES.RECLAMATION_CONSULT,
      flag: "user_sidebar_contact_us",
      hasPermission: generatePermissionMatcher(TypesPermissions.CONTACT),
    },
    {
      icon: <CampaignMetricsIcon width={24} height={24} />,
      iconActive: <CampaignMetricsIconActive className={classes.icon} />,
      text: "Métricas de campanhas",
      link: ROUTES.USER_ROUTES.DASH_POSH,
      flag: "user_sidebar_metrics_and_campaigns",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.CAMPAIGN_METRICS
      ),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Gestão de desempenho",
      link: ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT,
      flag: "user_sidebar_hide_performance_management",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.PERFORMANCE_MANAGEMENT
      ),
    },
    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Funcionários",
      link: ROUTES.USER_ROUTES.EMPLOYEE,
      flag: "user_sidebar_employee",
      hasPermission: generatePermissionMatcher(TypesPermissions.EMPLOYEE),
    },
    {
      icon: <NozzleIcon className={classes.icon} />,
      iconActive: <NozzleActiveIcon className={classes.icon} />,
      text: "Bico e Produto",
      link: ROUTES.USER_ROUTES.NOZZLE,
      flag: "user_sidebar_product_nozzle",
      hasPermission: generatePermissionMatcher(TypesPermissions.NOZZLES),
    },
    {
      icon: <ColetaIcon className={classes.icon} />,
      iconActive: <ColetaIconActive className={classes.icon} />,
      text: "Suas vendas",
      link: ROUTES.USER_ROUTES.SALES,
      flag: "user_sidebar_your_sales",
      hasPermission: generatePermissionMatcher(TypesPermissions.COLETA),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Desconto",
      link: ROUTES.USER_ROUTES.DISCOUNT,
      flag: "user_sidebar_hide_discount",
      hasPermission: generatePermissionMatcher(TypesPermissions.HOME),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Metas e comissões",
      link: ROUTES.USER_ROUTES.GOALS_AND_COMMISSIONS,
      flag: "user_sidebar_goal_and_commissions",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.GOALS_AND_COMMISSIONS
      ),
    },
    {
      icon: <ChangePasswordIcon className={classes.icon} />,
      iconActive: <ChangePasswordIconActive className={classes.icon} />,
      text: "Trocar senha",
      link: userState?.adminMode
        ? ROUTES.ADMIN_ROUTES.CHANGE_PASSWORD
        : ROUTES.USER_ROUTES.CHANGE_PASSWORD,
      flag: "user_sidebar_change_password",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.CHANGE_PASSWORD
      ),
    },
    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Ir para modo Admin",
      link: ROUTES.ADMIN_ROUTES.HOME,
      flag: "user_sidebar_sidebar_redirect_admin",
      render: () => userState?.adminMode,
      onClick: () => {
        localStorage.setItem("sidebarAdmin", "true");
      },
    },
  ].filter((entry) => !entry.render || entry?.render());

  const flags = useFlags(SIDEBAR_USER_FLAGS);
  const { flag_for_access_management } = useFlags([
    "flag_for_access_management",
  ]);

  const filteredMenuListUser = menuListUser.filter((item) => {
    const isAdmin =
      permissionSet?.ROLES.includes(Roles.ADMIN) ||
      permissionSet?.ROLES.includes(Roles.ADMINPORTAL);

    if (isAdmin) return flags[item.flag]?.enabled;

    const userPermission = permissionSet?.SYSTEM_MODULES;
    const hasPermission =
      item?.hasPermission && userPermission.some(item.hasPermission);
    const hasFlag = flags[item.flag]?.enabled;

    return flag_for_access_management.enabled
      ? hasPermission && hasFlag
      : hasFlag;
  });

  return (
    <List>
      {filteredMenuListUser.map((item, index) => (
        <SidebarMenuItem key={index} item={item} classes={classes} />
      ))}
    </List>
  );
};

export default ListDrawerUser;
